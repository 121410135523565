/******************************************************************************
 * Templates / Home
 *****************************************************************************/

@import '~/src/styles/main/mixins.scss';

body {
  /** delay prima di far partire animazione */
  --logo-show-up-delay: .5s;
  /** comparsa logo: animazione opacità */
  --logo-show-up-duration: 1s;

  --total-logo-show-up-duration: calc(var(--logo-show-up-delay) + var(--logo-show-up-duration));
  --delay-before-shaking: calc(var(--total-logo-show-up-duration) * .6);

  /** movimento inchino */
  --logo-shake-duration: .9s;          // lineare => curva/velocità
  /** scomparsa lettere */
  --logo-hide-duration: .7s;           // => sovrapposizione con shrink
  /** restringimento lettere */
  --logo-shrink-duration: .82s;        // lineare => curva
  /** comparsa punto */
  --logo-dot-reveal-duration: 1s;      // => delay
  /** movimento finale logo in alto a destra */
  --logo-scale-on-position-duration: 1s;

  --delay-before-hiding: calc((var(--delay-before-shaking) + var(--logo-shake-duration)) * .9);
  --delay-before-shrink: calc(var(--delay-before-hiding) + var(--logo-hide-duration));
  --time-before-logo-scaling: calc(
    (var(--delay-before-shrink) +
    var(--logo-shrink-duration)) + .3s);
  --total-animation-duration: calc(
    var(--time-before-logo-scaling) +
    var(--logo-scale-on-position-duration)
  );

  --animation-timing: cubic-bezier(0.64, 0, 0.32, 1);
  --move-and-scale-animation-timing: cubic-bezier(0.64, 0, 0.32, 1);
  --shrink-animation: cubic-bezier(0, 0, 0, .87);
  --shake-animation: cubic-bezier(0, 0.41, 1, 0.59);

  --ap-c-page--Grid-Template: var(--ap-global--content-grid--GridTemplateColumns);
  --ap-c-page--Grid-Gap: clamp(10px, 2vw, 15px);

  --ap-c-page--sm--Grid-Template: var(--ap-global--page-grid--sm--GridTemplateColumns);
}

/**
 * Inline: small
 */

@media all and (min-width: 67.5rem) {
  body {
    --ap-c-page--Grid-Template: var(--ap-c-page--sm--Grid-Template);
    --ap-c-page--Grid-Gap: clamp(15px, 1.5vw, 26px);
}
}


/**
 * Component
 */

body {
  // display: grid;
  // grid-template-columns: var(--ap-c-page--Grid-Template);
  // grid-gap: var(--ap-c-page--Grid-Gap);
}

.ap-c-header .ap-title {
  opacity: 0;
}

.ap-website-loading {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  background-color: var(--ap-c-page--Color);

  animation: reveal-background var(--logo-scale-on-position-duration) forwards;
  animation-timing-function: var(--animation-timing);
  animation-delay: var(--time-before-logo-scaling);

  .container {
    box-sizing: content-box;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-block-start: calc(var(--space-xl) * 1.12);
    padding-inline: var(--ap-global--page--paddingsize);
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);

    animation-timing-function: var(--move-and-scale-animation-timing);
    animation-name: move-title;
    animation-duration:
      var(--logo-scale-on-position-duration);
    animation-delay:
      var(--time-before-logo-scaling);
    animation-fill-mode: forwards;
  }

  .ap-title {
    opacity: 0;
    animation-timing-function: var(--animation-timing);
    animation-name: show-up;
    animation-duration:
      var(--logo-show-up-duration);
    animation-delay:
      var(--logo-show-up-delay);
    animation-fill-mode: forwards;
    color: var(--ap-c-page--BackgroundColor);

    span {
      font-size: 3.313rem;
      line-height: 2.875rem;
      animation: scale-title var(--logo-scale-on-position-duration) forwards;
      animation-timing-function: var(--move-and-scale-animation-timing);
      animation-delay: var(--time-before-logo-scaling);
      // fix for italic safari cut of characters
      padding-inline: 15px;
      margin-inline: -15px;

      @-moz-document url-prefix() {
        padding-inline: 0;
        margin-inline: 0;
      }

      @media all and (min-width: 67.5rem) {
        font-size: 5.313rem;    // 85px
        line-height: 4.875rem;  // 78px
      }

      &.ap-u-italic {
        font-variation-settings: 'ital' 100;
        animation-timing-function: var(--shake-animation), var(--move-and-scale-animation-timing);
        animation-name: shake-letters-and-still, scale-title;
        animation-duration:
          var(--logo-shake-duration),
          var(--logo-scale-on-position-duration);
        animation-delay:
          var(--delay-before-shaking),
          var(--time-before-logo-scaling);
        animation-fill-mode: forwards, forwards;
      }

      &.hide-italic {
        letter-spacing: 0;
        opacity: 1;
        display: inline-flex;
        font-variation-settings: 'ital' 100;
        animation-timing-function: var(--shake-animation), var(--animation-timing), var(--shrink-animation), var(--move-and-scale-animation-timing);
        animation-name: shake-letters-and-still, hide-letters, shrink-letters, scale-title;
        animation-duration:
          var(--logo-shake-duration),
          var(--logo-hide-duration),
          var(--logo-shrink-duration),
          var(--logo-scale-on-position-duration);
        animation-delay:
          var(--delay-before-shaking),
          var(--delay-before-hiding),
          var(--delay-before-shrink),
          var(--time-before-logo-scaling);
        animation-fill-mode: forwards, forwards, forwards, forwards;
      }

      &.hide-shaking {
        letter-spacing: 0;
        opacity: 1;
        display: inline-flex;
        animation-timing-function: var(--shake-animation), var(--animation-timing), var(--shrink-animation), var(--move-and-scale-animation-timing);
        animation-name: shake-letters, hide-letters, shrink-letters, scale-title;
        animation-duration:
          var(--logo-shake-duration),
          var(--logo-hide-duration),
          var(--logo-shrink-duration),
          var(--logo-scale-on-position-duration);
        animation-delay:
          var(--delay-before-shaking),
          var(--delay-before-hiding),
          var(--delay-before-shrink),
          var(--time-before-logo-scaling);
        animation-fill-mode: forwards, forwards, forwards, forwards;
      }

      &.hide {
        letter-spacing: 0;
        opacity: 1;
        display: inline-flex;
        animation-timing-function: var(--animation-timing), var(--shrink-animation), var(--move-and-scale-animation-timing);
        animation-name: hide-letters, shrink-letters, scale-title;
        animation-duration:
          var(--logo-hide-duration),
          var(--logo-shrink-duration),
          var(--logo-scale-on-position-duration);
        animation-delay:
          var(--delay-before-hiding),
          var(--delay-before-shrink),
          var(--time-before-logo-scaling);
        animation-fill-mode: forwards, forwards, forwards;
      }

      &.shaking {
        animation-timing-function: var(--shake-animation), var(--move-and-scale-animation-timing);
        animation-name: shake-letters, scale-title;
        animation-duration:
          var(--logo-shake-duration),
          var(--logo-scale-on-position-duration);
        animation-delay:
          var(--delay-before-shaking),
          var(--time-before-logo-scaling);
        animation-fill-mode: forwards, forwards;
      }

      &.reveal {
        opacity: 0;
        animation-timing-function: cubic-bezier(0, 0, .25, 1), var(--move-and-scale-animation-timing);
        animation-name: show-up, scale-title;
        animation-duration:
          var(--logo-dot-reveal-duration),
          var(--logo-scale-on-position-duration);
        animation-delay:
          // var(--delay-before-shaking),
          calc((var(--delay-before-shrink) + var(--logo-shrink-duration)) * .8),
          var(--time-before-logo-scaling);
        animation-fill-mode: forwards, forwards;
      }
    }
  }

  &.skip-animation {
    animation-delay: 0ms !important;
    animation-duration: 1ms !important;
    animation-fill-mode: forwards !important;

    * {
      animation-delay: 0ms !important;
      animation-duration: 1ms !important;
      animation-fill-mode: forwards !important;
    }
  }
}

/******************************************************************************
 * Animations
 *****************************************************************************/

@keyframes show-up {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes shake-letters {
  from {font-variation-settings: 'ital' 100;}
  50% {font-variation-settings: 'ital' 900;}
  to {font-variation-settings: 'ital' 100;}
}

@keyframes shake-letters-and-still {
  from {font-variation-settings: 'ital' 100;}
  60% {font-variation-settings: 'ital' 900;}
  to {font-variation-settings: 'ital' 900;}
}

@keyframes hide-letters {
  from {opacity: 1;}
  to {opacity: 0;}
}

@keyframes shrink-letters {
  from {letter-spacing: 0;}
  to {letter-spacing: -.7em;}
}

@keyframes scale-title {
  to {@include logo-font;}
}

@keyframes move-title {
  to {top: 0; right: 0; transform: translate(0, 0); min-width: 0;}
}

@keyframes reveal-background {
  30% {background-color: var(--ap-c-page--Color);}
  100% {background-color: transparent;}
}

main {
  background-color: var(--ap-c-page--BackgroundColor);
  position: relative;
  // margin-top: -6.5rem;

  > .main-container {
    max-width: var(--ap-global--breakpoints--block-xl);
    margin: 0 auto;
  }

  @media (min-width: 67.5rem) {
    padding-top: clamp(2.5rem, 7vw, 4.5rem);
    // margin-top: clamp(-21.5rem, -30vw, -16.5rem);
  }

  .ap-c-section {
    &:first-of-type {
      .main-container.mobile {
        margin-top: -6.5rem;
      }
    }
    &:last-of-type {
      margin-bottom: -11rem;
      position: relative;
      z-index: 2;

      @media (min-width: 67.5rem) {
        margin-bottom: clamp(-33rem, -27vw, -18rem);
      }
    }
  }

}

section.ap-c-intro + section {
  position: relative;

  @media (min-width: 67.5rem) {
    background-color: var(--ap-c-page--BackgroundColor);
    // margin-top: clamp(-30.5rem, -30vw, -23.5rem);
  }
}

.ap-c-footer {
  position: relative;
  // margin-top: -17rem;
  // z-index: -1;
  padding-block-start: 17rem;

  @media (min-width: 67.5rem) {
    // margin-top: clamp(-47rem, -55vw, -17rem);
    padding-block-start: clamp(17rem, 35vw, 39rem);
  }
}

body.home {
  .ap-c-header.custom-height {
    height: 0 !important;
  }
}
